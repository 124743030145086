import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import { CustomMultiSelectDropdown } from 'components/CustomMultiSelectDropdown';
import { CustomSingleSelectDropdown } from 'components/CustomSingleSelectDropdown';
import PropTypes from 'prop-types';

// Define validation schema
const getValidationSchema = (questions) => {
    return Yup.object().shape(
        questions.reduce((acc, curr) => {
            if (curr.required) {
                acc[curr.question] =
                    curr.select === 'multi-select'
                        ? Yup.array().min(1, 'Please complete this required field')
                        : Yup.string().required('Please complete this required field');
            }
            return acc;
        }, {})
    );
};

const QuestionForm = () => {
    const questions = [
        {
            question: 'What are your favorite programming languages?',
            options: [
                { id: '1', header: 'JavaScript' },
                { id: '2', header: 'Python' },
                { id: '3', header: 'Java' },
                { id: '4', header: 'C++' },
            ],
            select: 'multi-select',
            required: true,
        },
        {
            question: 'Which cloud platforms do you prefer?',
            options: [
                { id: '1', header: 'AWS' },
                { id: '2', header: 'Azure' },
                { id: '3', header: 'Google Cloud' },
                { id: '4', header: 'IBM Cloud' },
            ],
            select: 'single-select',
            required: true,
        },
        {
            question: 'What are your preferred development tools?',
            options: [
                { id: '1', header: 'VSCode' },
                { id: '2', header: 'IntelliJ IDEA' },
                { id: '3', header: 'Sublime Text' },
                { id: '4', header: 'Atom' },
            ],
            select: 'multi-select',
            required: false,
        },
        {
            question: 'Which operating systems do you use?',
            options: [
                { id: '1', header: 'Windows' },
                { id: '2', header: 'macOS' },
                { id: '3', header: 'Linux' },
                { id: '4', header: 'Other' },
            ],
            select: 'single-select',
            required: true,
        },
        {
            question: 'What version control systems do you use?',
            options: [
                { id: '1', header: 'Git' },
                { id: '2', header: 'SVN' },
                { id: '3', header: 'Mercurial' },
                { id: '4', header: 'Other' },
            ],
            select: 'multi-select',
            required: false,
        },
        {
            question: 'Which databases do you prefer?',
            options: [
                { id: '1', header: 'MySQL' },
                { id: '2', header: 'PostgreSQL' },
                { id: '3', header: 'MongoDB' },
                { id: '4', header: 'SQLite' },
            ],
            select: 'single-select',
            required: true,
        },
        {
            question: 'Which front-end framework do you prefer?',
            options: [
                { id: '1', header: 'React' },
                { id: '2', header: 'Vue.js' },
                { id: '3', header: 'Angular' },
                { id: '4', header: 'Svelte' },
            ],
            select: 'single-select',
            required: true,
        },
        {
            question: 'Which back-end framework do you prefer?',
            options: [
                { id: '1', header: 'Node.js' },
                { id: '2', header: 'Django' },
                { id: '3', header: 'Spring Boot' },
                { id: '4', header: 'Ruby on Rails' },
            ],
            select: 'single-select',
            required: true,
        },
        {
            question: 'Which CI/CD tools do you prefer?',
            options: [
                { id: '1', header: 'Jenkins' },
                { id: '2', header: 'Travis CI' },
                { id: '3', header: 'CircleCI' },
                { id: '4', header: 'GitLab CI' },
            ],
            select: 'multi-select',
            required: false,
        },
        {
            question: 'What testing frameworks do you use?',
            options: [
                { id: '1', header: 'Jest' },
                { id: '2', header: 'Mocha' },
                { id: '3', header: 'JUnit' },
                { id: '4', header: 'PyTest' },
            ],
            select: 'multi-select',
            required: false,
        },
        {
            question: 'Which DevOps tools do you use?',
            options: [
                { id: '1', header: 'Docker' },
                { id: '2', header: 'Kubernetes' },
                { id: '3', header: 'Ansible' },
                { id: '4', header: 'Terraform' },
            ],
            select: 'multi-select',
            required: false,
        },
        {
            question: 'Which mobile frameworks do you prefer?',
            options: [
                { id: '1', header: 'React Native' },
                { id: '2', header: 'Flutter' },
                { id: '3', header: 'Ionic' },
                { id: '4', header: 'Cordova' },
            ],
            select: 'single-select',
            required: true,
        },
        {
            question: 'Which communication tools do you prefer?',
            options: [
                { id: '1', header: 'Slack' },
                { id: '2', header: 'Microsoft Teams' },
                { id: '3', header: 'Zoom' },
                { id: '4', header: 'Google Meet' },
            ],
            select: 'single-select',
            required: true,
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const questionsPerPage = 5;
    const totalPages = Math.ceil(questions.length / questionsPerPage);

    const initialValues = questions.reduce((acc, curr) => {
        acc[curr.question] = curr.select === 'multi-select' ? [] : '';
        return acc;
    }, {});

    const handleSubmit = (values) => {
        console.log('Form Values:', values);
        // Handle form submission
    };

    const handleNextPage = async (validateForm) => {
        const errors = await validateForm(); // Validate the form

        if (Object.keys(errors).length === 0) {
            if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1);
            }
        }
        // Errors are displayed under the respective fields automatically by Formik
    };

    const handlePageChange = (e) => {
        setCurrentPage(Number(e.target.value));
    };

    const currentQuestions = questions.slice((currentPage - 1) * questionsPerPage, currentPage * questionsPerPage);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(questions)} // Set validation schema
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, setFieldValue, validateForm }) => (
                <Form>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2>Questionnaire</h2>
                        <div>
                            <select
                                id='pageSelect'
                                value={currentPage}
                                onChange={handlePageChange}
                                style={{ width: '200px', padding: '5px' }}
                            >
                                {Array.from({ length: totalPages }, (_, index) => {
                                    const startQuestion = index * questionsPerPage + 1;
                                    const endQuestion = Math.min((index + 1) * questionsPerPage, questions.length);
                                    return (
                                        <option key={index + 1} value={index + 1}>
                                            {`Page ${index + 1} (Q${startQuestion} - Q${endQuestion})`}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>

                    {currentQuestions.map((questionObj, index) => (
                        <div key={index}>
                            <h4>
                                {`Q${(currentPage - 1) * questionsPerPage + index + 1} ${questionObj.question}`}
                                {!questionObj.required && (
                                    <span style={{ color: 'gray', marginLeft: '5px' }}>(Optional)</span>
                                )}
                            </h4>
                            {questionObj.select === 'single-select' ? (
                                <div>
                                    <CustomSingleSelectDropdown
                                        name={questionObj.question}
                                        dropdownOption={questionObj.options}
                                        value={values[questionObj.question]}
                                        onChange={(selectedOption) =>
                                            setFieldValue(questionObj.question, selectedOption)
                                        }
                                    />
                                    {/* Display error message for required fields */}
                                    {errors[questionObj.question] && touched[questionObj.question] && (
                                        <div style={{ color: 'red' }}>{errors[questionObj.question]}</div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <CustomMultiSelectDropdown
                                        name={questionObj.question}
                                        dropdownOption={questionObj.options}
                                        value={values[questionObj.question]}
                                        onChange={(selectedOptions) =>
                                            setFieldValue(questionObj.question, selectedOptions)
                                        }
                                    />
                                    {/* Display error message for required fields */}
                                    {errors[questionObj.question] && touched[questionObj.question] && (
                                        <div style={{ color: 'red' }}>{errors[questionObj.question]}</div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}

                    <button type='button' onClick={() => handleNextPage(validateForm)}>
                        {currentPage < totalPages ? 'Next' : 'Submit'}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

QuestionForm.propTypes = {
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string.isRequired,
            options: PropTypes.array.isRequired,
            select: PropTypes.oneOf(['single-select', 'multi-select']).isRequired,
        })
    ).isRequired,
};

export default QuestionForm;
